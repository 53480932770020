<script setup>
import ViewportHelper from '~/components/viewport-helper.vue'
import MenuDesktop from '~/layouts/client-menu-desktop.vue'
import MenuMobile from '~/layouts/client-menu-mobile.vue'
import Profile from '~/layouts/profile.vue'

useHead({
  htmlAttrs: {
    class: "text-sm xl:text-base 2xl:text-xl",
    dir: "ltr",
    lang: "en"
  },
  bodyAttrs: {
    class: "antialiased bg-page text-default tracking-tight",
  },
  title: 'TGI - Client Portal',
  meta: [
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0, user-scalable=no' },
    { name: 'description', content: 'Protect what matters most with TGI. More than just insurance, we offer you a peace of mind and financial security.'},
  ],
})
</script>

<template>
  <div>
    <!-- Viewport Helper (development) -->
    <ViewportHelper />

    <!-- page wrapper -->
    <div class="flex flex-col h-screen">

      <div class="flex flex-col md:flex-row flex-1">

        <!-- menu: mobile -->
        <MenuDesktop />

        <!-- page container -->
        <div class="flex-1 flex flex-col shadow-xl">
          <div class="p-4 xl:px-12 2xl:container">
            <Profile /> <!-- profile bar -->
            <NuxtPage /> <!-- page content -->
          </div>
          <!-- mobile spacing so content isnt hidden in mobile -->
          <div class="print-hidden md:hidden h-24"></div>
        </div>

      </div>

      <!-- menu: mobile -->
      <MenuMobile />

    </div>
  </div>
</template>

<style lang="scss">
/*
//https://primevue.org/csslayer/#tailwind
@layer tailwind-base, primevue, tailwind-utilities;
@layer tailwind-base {
    @tailwind base;
}
@layer tailwind-utilities {
    @tailwind components;
    @tailwind utilities;
}
@layer utilities {
  .max-h-screen-minus-menu {
    // mobile
    @media (max-width: 768px) {
      max-height: calc(100dvh - 190px);
    }
  }
}
*/

:root {
  // tgi
  --tgi-font-sans: 'Inter';
  --tgi-font-serif: var(--tgi-font-sans);
  --tgi-font-heading: var(--tgi-font-sans);
  --tgi-color-primary: rgb(1 97 239);
  --tgi-color-secondary: rgb(1 84 207);
  --tgi-color-accent: rgb(109 40 217);
  --tgi-color-text-heading: rgb(0 0 0);
  --tgi-color-text-default: rgb(16 16 16);
  --tgi-color-text-muted: rgb(16 16 16 / 66%);
  --tgi-color-bg-page: rgb(255 255 255);
  --tgi-color-bg-page-dark: rgb(3 6 32);
  --tgi-color-bg-page-accent: rgb(251, 251, 251);

  // primevue (tailwind blue)
  --primary-50: 239 246 255;
  --primary-100: 219 234 254;
  --primary-200: 191 219 254;
  --primary-300: 147 197 253;
  --primary-400: 96 165 250;
  --primary-500: 59 130 246;
  --primary-600: 37 99 235;
  --primary-700: 29 78 216;
  --primary-800: 30 64 175;
  --primary-900: 30 58 138;
  --primary-950: 23 37 84;
  --surface-0: 255 255 255;
  --surface-50: 248 250 252;
  --surface-100: 241 245 249;
  --surface-200: 226 232 240;
  --surface-300: 203 213 225;
  --surface-400: 148 163 184;
  --surface-500: 100 116 139;
  --surface-600: 71 85 105;
  --surface-700: 45 55 72;
  --surface-800: 30 41 59;
  --surface-900: 15 23 42;
  --surface-950: 3 6 23;

  --primary: var(--tgi-color-primary);
  --primary-inverse: var(--surface-0);
  --primary-hover: var(--primary-600);
  --primary-active-color: var(--primary-600);
  --primary-highlight-opacity: 0.1;
  --primary-highlight-inverse: var(--primary-700);
  --primary-highlight-hover-opacity: 0.2;

  ::selection {
    background-color: lavender;
  }
}

// apply shadow and rounding to all primevue panels
.p-panel {
  @apply shadow-md rounded-lg
}
</style>
